<template>
  <div
    class="modal-backdrop bg-mainred pt-8 py-8 px-8 mb-8 text-white"
    :class="{ hidden: isClosed }"
  >
    <div class="modal">
      <header class="modal-header shadow-none flex flex-row justify-between items-center mb-8">
        <slot name="header"></slot>
        <button aria-label="Open Search Modal" @click="close" class="w-16">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Ebene_15"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 60 60"
            xml:space="preserve"
            fill="none"
            stroke="#ffffff"
            stroke-width="6.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="close"
          >
            <path d="M56.7 3.3L3.3 56.7M3.3 3.3l53.4 53.4" class="st0" />
          </svg>
        </button>
      </header>
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  data() {
    return {
      isClosed: false
    };
  },
  methods: {
    close() {
      this.isClosed = true;
    }
  }
};
</script>

<style>
.modal-header::before {
  box-shadow: none;
}
</style>
