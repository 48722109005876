<template>
  <nav id="subnav">
    <p class="mb-0">subnav</p>
  </nav>
</template>

<script>
export default {};
</script>

<style>
</style>