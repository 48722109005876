<template>
  <div class="akkordeon border-solid border border-gray-400 p-8 mb-8 bg-lightgrey">
    <header
      @click="toggleAkkordeon()"
      class="akkordeon-header shadow-none flex flex-row justify-between items-center cursor-pointer"
      :class="akkordeonid"
      :id="akkordeonid"
    >
      <slot name="header" class="pr-6" :class="akkordeonid"></slot>
      <button
        aria-label="open accordeon"
        class="transition duration-300 ease-in-out transform"
        :class="[{ 'rotate-180': isOpen}, akkordeonid ]"
      >
        <triangulu color="#241f21" class="w-16" :class="akkordeonid"></triangulu>
      </button>
    </header>
    <div v-if="isOpen" class="akkordeon-body">
      <slot name="body" :class="akkordeonid"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "akkordeon",
  data() {
    return {
      isOpen: false
    };
  },
  props: ["akkordeonid"],
  methods: {
    toggleAkkordeon: function() {
      this.isOpen = !this.isOpen;
      // this.$gtag.event(<action>, {
      //   'event_category': <category>,
      //   'event_label': <label>,
      //   'value': <value>
      // })
    }
  }
};
</script>

<style>
.akkordeon-header::before {
  box-shadow: none;
}

.cta {
  margin-left: 0;
}
</style>
