<template>
  <div>
    <button
      aria-label="open search modal
      "
      @click="toggleSearch()"
      class="searchicon p-6 pl-24 mb-6 bg-lightgrey md:w-1/2 lg:bg-transparent lg:p-0 lg:m-0 lg:w-24"
    >
      <lupe color="#b9bbb9" class="w-16 lupe"></lupe>
    </button>
  </div>
</template>

<script>
export default {
  name: "Searchicon",
  methods: {
    toggleSearch: function() {
      this.$emit("searchToggled");
    }
  }
};
</script>

<style></style>
