<template>
  <ul class="langswitcher">
    <li
      v-for="lang in langs"
      :key="lang.id"
      class="lang inline-block px-1 text-maingrey"
      :class="{ active: lang.active }"
    >
      <a :href="lang.url" class="uppercase md:text-small hover:text-black">{{ lang.language }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "LangSwitcher",
  props: ["langs"]
};
</script>

<style lang="scss">
.langswitcher {
  .lang:after {
    content: " |";
  }

  .lang:last-child:after {
    content: "";
  }

  .active a {
    color: black;
  }
}
</style>
