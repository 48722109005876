<template>
  <div id="vuehead" class="container relative">
    <div class="flex justify-between lg:inline-block">
      <header-logo :homelink="homelink" class="flex"></header-logo>
      <burger v-if="$mq === 'sm' || $mq === 'lg'" v-on:toggle="onToggle" class="mr-5 sm:mr-12"></burger>
    </div>
    <main-navigation
      id="mainnav"
      :nodes="nodes"
      :navIsActive="navIsActive"
      :langs="langs"
      :homelink="homelink"
      v-if="navIsActive || $mq === 'xl'"
      class="mt-32 ml-24 lg:m-0 lg:mt-1 lg:w-1/2 lg:w-full"
    ></main-navigation>
    <meta-nav
      id="metanav"
      v-if="navIsActive || $mq === 'xl'"
      :langs="langs"
      :homelink="homelink"
      :loginlinks="loginlinks"
      class="lg:absolute"
    ></meta-nav>
  </div>
</template>

<script>
export default {
  name: "VueHeader",
  props: ["nodes", "homelink", "langs", "loginlinks"],

  data() {
    return {
      navIsActive: false,
      searchIcon: this.homelink + "/assets/fixies/Lupe.svg"
    };
  },
  methods: {
    onToggle(active) {
      this.navIsActive = !this.navIsActive;
    }
  }
};
</script>

<style lang="scss">
@media screen and (min-width: 768px) {
  #metanav {
    top: 0;
    left: 58%;
    right: 2rem;
  }
}
</style>
