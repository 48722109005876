<template>
  <div class="faqordeon">
    <header
      @click="toggleAkkordeon()"
      class="faqordeon-header shadow-none flex flex-row justify-between items-center cursor-pointer lg:cursor-default lg:w-1/2 lg:flex-none font-medium"
    >
      <slot name="header"></slot>
      <button
        aria-label="Show Answer"
        class="w-16 mr-12 ml-4 transition duration-300 ease-in-out transform lg:hidden"
        :class="{ 'rotate-180': isOpen }"
      >
        <triangulu color="#241f21"></triangulu>
      </button>
    </header>
    <div v-if="isOpen || this.$mq === 'xl'" class="answer">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faqordeon",
  data() {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleAkkordeon: function() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style>
.faqordeon-header::before {
  box-shadow: none;
}

.cta {
  margin-left: 0;
}
</style>
