<template>
  <div id="logButt" class="cursor-pointer md:w-1/2">
    <div
      @click="toggleAkkordeon()"
      class="p-6 pl-24 lg:p-0 lg:px-12 flex justify-between items-center bg-mainred text-white"
    >
      <h3 class="lg:text-small lg:py-10">Login</h3>
      <button
        aria-label="Login Link"
        class="mr-3 transition duration-300 ease-in-out transform"
        :class="{ 'rotate-180': isOpen }"
      >
        <triangulu color="#ffffff" class="w-16"></triangulu>
      </button>
    </div>
    <ul v-if="isOpen" id="logins" class>
      <li
        v-for="link in logLinks"
        :key="link.id"
        class="text-maingrey bg-white hover:text-mainred p-6 pl-24 lg:pl-6 border-2 box-border border-mainred flex items-center justify-between pr-10"
      >
        <a
          :href="link.url"
          target="_blank"
          class="w-full flex justify-between items-center lg:text-small"
        >
          <p class="mb-0">
            {{
            link.titel[selectedLang]
            }}
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Ebene_13"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 60 60"
            xml:space="preserve"
            fill="none"
            stroke-width="6.3"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-16 arrow"
          >
            <path d="M3.3 30h53.4M40.4 45.2L56.7 30 40.4 14.8" class="st0" />
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["selectedLang", "loginlinks"],
  data() {
    return {
      isOpen: false,
      logLinks: [
        {
          titel: ["Tierhalter", "avec compte", "con account"],
          url: this.loginlinks[0],
          id: 1
        },
        {
          titel: ["Tierarzt", "vétérinaires", "veterinari"],
          url: this.loginlinks[1],
          id: 2
        },
        {
          titel: ["Besucher", "visiteur", "visitatori"],
          url: this.loginlinks[2],
          id: 3
        }
      ]
    };
  },
  methods: {
    toggleAkkordeon: function() {
      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss">
#logButt:hover {
  ul {
    display: block;
  }
}

#logins {
  li {
    .arrow {
      stroke: #b9bab9;
    }
    &:hover {
      .arrow {
        stroke: #e40521;
      }
    }
    &:nth-child(2n) {
      border-top: 0;
      border-bottom: 0;
    }
  }
}
</style>
