<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="lupe"
    x="0"
    y="0"
    version="1.1"
    viewBox="0 0 60 60"
    xml:space="preserve"
    fill="none"
    :stroke="color"
    stroke-width="3.266"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M39.2 35.7L52.5 49c1 1 1 2.5 0 3.5s-2.5 1-3.5 0L35.7 39.2" class="st0" />
    <path
      d="M42.9 24.8c0 10-8.1 18.1-18.1 18.1S6.7 34.8 6.7 24.8 14.8 6.7 24.8 6.7s18.1 8.1 18.1 18.1z"
      class="st0"
    />
  </svg>
</template>

<script>
export default {
  name: "Lupe",
  props: ["color"]
};
</script>

<style >
#lupe {
  max-height: 5rem;
}
</style>
