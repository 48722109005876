import Vue from "vue";
import Components from "./components";
import "./app.css";

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    // id: "UA-1234567-1"
  }
});

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

import VueMq from 'vue-mq'

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 768,
    lg: 1024,
    xl: 1920
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})
Vue.config.productionTip = false;
new Vue({
  el: "#app",
  delimiters: ["${", "}"]
});