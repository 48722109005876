<template>
  <div>
    <!-- <h1>This is a h1 Header</h1> -->
    <h2>This is a h2 Header</h2>
    <h3>This is a h3 Header</h3>
    <!-- <h4>This is a h4 Header</h4>
    <h5>This is a h5 Header</h5>
    <h6>This is a h6 Header</h6> -->
    <p>
      This is p. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      Repellat, tempora!
    </p>
    <p class="small">
      This is p.small. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
      Repellat, tempora!
    </p>
    <p>this is a <a href="#">link</a> in a p.</p>
    <div>This is a <a href="#">outside</a> of a p</div>
    <p class="cap">this is a caption.</p>
    <p class="answer">
      This is an answer.
    </p>
    <p>
      These are Textvariants like <b>bold</b>, <i>italic</i> and
      <u>underlined</u> .
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
