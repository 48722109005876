<template>
  <div class="flex md:w-1/2 bg-lightgrey p-4 mb-8">
    <lupe color="#b9bbb9" class="w-16"></lupe>
    <form
      action="/search/results"
      autocomplete="on"
      class="flex w-full justify-between md:w-4/5 lg:w-full"
    >
      <input
        type="text"
        name="search"
        class="bg-lightgrey placeholder-white focus:outline-none md:w-1/2 md:pl-4 lg:w-4/5 lg:ml-8"
        onfocus="this.placeholder = ''"
      />
      <input
        id="search_submit"
        :value="suchen[selectedLang]"
        type="submit"
        class="border border-solid border-gray-600 bg-transparent cursor-pointer p-4"
      />
    </form>
  </div>
</template>

<script>
export default {
  name: "Searchfield",
  props: ["selectedLang", "homelink"],
  data() {
    return {
      suchen: ["suchen", "chercher", "cercare"],
      action: this.homelink + "search/results"
    };
  }
};
</script>

<style></style>
