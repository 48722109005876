<template>
  <div class="kitties w-screen -ml-8 sm:w-full sm:ml-0">
    <img :src="currentImg.src" :srcset="currentImg.srcset" sizes="100vw" alt="KittyPic" />
    <!-- <picture class="flex">
      <source :srcset="currentImg.srcsetWebP" sizes="100vw" type="image/webp" />
      <img :src="currentImg.src" :srcset="currentImg.srcset" sizes="100vw" class="kitty" />
    </picture>-->

    <button
      aria-label="Refresh Button"
      @click="clicked()"
      class="absolute top-0 right-0 w-32 mr-8 mt-8 p-8 transition duration-300 ease-in-out transform focus:outline-none"
      :class="{ isRotating: isRotating, 'reminder': reminder }"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
        <path
          fill="none"
          stroke="#ffffff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="5.607"
          d="M45.8,46.6c-4.1,4.8-9.8,8.1-16.3,8.7c-13.4,1.1-25-9.2-26.1-23.1C2.3,18.1,12.2,5.9,25.6,4.7
		c13.3-1.3,25,9.1,26.1,23 M56.6,18.9l-5,8.7l-8.1-5.2"
        />
      </svg>
    </button>
    <button
      aria-label="scroll to start"
      href="#start"
      v-scroll-to="'#start'"
      class="absolute bottom-0 right-0 w-32 mr-8 transform rotate-90 mb-48 sm:mb-xxl lg:hidden"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Ebene_11"
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 60 60"
        xml:space="preserve"
        fill="none"
        stroke="#ffffff"
        stroke-width="3.1"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M3.3 30h53.4M40.4 45.2L56.7 30 40.4 14.8" class="st0" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "Catwalk",
  props: ["images"],
  data() {
    return {
      currentIndex: 4,
      isRotating: false,
      counter: 7,
      reminder: false,
      timeroff: false
    };
  },
  mounted: function() {
    this.randomImg();
    this.startTheClock();
  },

  methods: {
    next: function() {
      this.currentIndex += 1;
    },
    clicked: function() {
      this.isRotating = true;
      this.randomImg();
      this.timeroff = true;
      setTimeout(() => (this.isRotating = false), 600);
    },
    randomImg: function() {
      let rand = Math.floor(Math.random() * this.images.length);
      if (rand != this.currentIndex) {
        this.currentIndex = rand;
      } else {
        this.randomImg();
      }
    },
    decrementCounter: function() {
      if (this.timeroff == false) {
        if (this.counter > 0) {
          this.counter--;
        } else {
          this.remindThem();
          this.counter = 7;
        }
      }
    },
    remindThem: function() {
      this.reminder = true;
      setTimeout(() => (this.reminder = false), 1000);
    },
    startTheClock: function() {
      setInterval(() => {
        this.decrementCounter();
      }, 1000);
    }
  },
  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
};
</script>

<style>
#start {
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    bottom: 15rem;
  }
}

@keyframes rotates {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.isRotating {
  animation-name: rotates;
  animation-duration: 0.6s;
  animation-fill-mode: forwards;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.reminder {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
