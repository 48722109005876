<template>
  <div>
    <label class="inline-block p-8 mb-2 bg-lightgrey cursor-pointer" for="attachment">{{ uptext }}</label>
    <input
      multiple
      accept="..gif, .jpg, .png, .txt, .doc, .docx, .xls, .xlsx, .csv, .xml"
      type="file"
      name="attachment"
      id="attachment"
      @change="
        filesChange($event.target, $event.target.name, $event.target.files)
      "
      class="hidden"
    />
    <div class="preview">
      <p v-if="!filesToUpload.length" class="small">{{tharulez}}</p>
      <ul v-else>
        <li v-for="file in filesToUpload" :key="file.name" class="list-disc list-inside">
          {{ file.name }},
          {{returnFileSize(file.size)}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maxFileSize: 9500000,
      validFileExtensions: [
        ".pdf",
        ".gif",
        ".jpg",
        ".png",
        ".txt",
        ".doc",
        ".docx",
        ".xls",
        ".xlsx",
        ".csv",
        ".xml"
      ],
      fileListSize: 0,
      preview: "No files currently selected for upload",
      filesToUpload: []
    };
  },
  props: ["uptext", "maxerrtext", "typeerrtext", "tharulez"],
  methods: {
    filesChange(field, fieldName, fileList) {
      this.filesToUpload = [];
      for (const file of fileList) {
        // check allowed filetypes
        if (this.validateFile(file) != true) {
          alert(`${this.typeerrtext} ${this.validateFile(file)}`);
          return;
        }
        // check max filesize
        else if (this.calcFileSize(fileList) >= this.maxFileSize) {
          alert(this.maxerrtext);
          return;
        } else {
          this.filesToUpload.push(file);
        }
      }
      if (this.filesToUpload.length) {
        this.fileListEmpty = false;
      }
    },

    validateFile(file) {
      let ext = (file.name.match(/\.([^.]*?)(?=\?|#|$)/) || [])[0];
      if (this.validFileExtensions.includes(ext.toLowerCase())) {
        return true;
      } else return ext;
    },

    calcFileSize(fileList) {
      let fileListSize = 0;
      for (const file of fileList) {
        fileListSize += file.size;
      }

      return fileListSize;
    },

    returnFileSize(number) {
      if (number < 1024) {
        return number + "bytes";
      } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + "KB";
      } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + "MB";
      }
    }
  }
};
</script>

<style></style>
