<template>
  <div>
    <div
      class="flex flex-wrap items-stretch w-full lg:w-2/5 xl:w-1/3 my-6 p-6 relative bg-lightgrey mb-24"
    >
      <div class="flex -mr-px">
        <lupe color="#b9bbb9" class="w-16"></lupe>
      </div>
      <input
        type="text"
        class="flex-grow placeholder-white bg-lightgrey focus:outline-none pl-6"
        v-model="search"
        onfocus="this.placeholder = ''"
      />
    </div>
    <div class="border-b-2 border-black">
      <faqordeon
        v-for="faq in filteredList"
        :key="faq.id"
        class="border-t-2 border-black lg:flex lg:items-baseline"
      >
        <div slot="header" class="faqHeader py-6 flex-1" v-html="faq.frage"></div>
        <div slot="body">
          <p>
            <span v-html="faq.antwort" class="text faq-text"></span>
          </p>
        </div>
      </faqordeon>
    </div>

    <div v-if="filteredList.length <= 0">
      <p v-if="currentlang == 'it'">Nessune «FAQs» trovate</p>
      <p v-else-if="currentlang == 'fr'">Pas trouvé de «FAQs»</p>
      <p v-else>Keine FAQs gefunden</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "faqlist",
  props: ["faqs", "suche", "currentlang"],
  data() {
    return {
      search: "",
      show: false
    };
  },
  computed: {
    filteredList() {
      return this.faqs.filter(faq => {
        let frageantwort =
          faq.frage.toLowerCase().includes(this.search.toLowerCase()) ||
          faq.antwort.toLowerCase().includes(this.search.toLowerCase());
        return frageantwort;
      });
    }
  },
  methods: {
    toggle: function() {
      this.show = !this.show;
    }
  }
};
</script>

<style>
.faq-text ul {
  padding-left: 0;
}
</style>
