<template>
  <nav id="metanav" class v-on:deactivate="deactivate">
    <ul class="h-full flex flex-col-reverse lg:flex-row lg:justify-end lg:w-full">
      <li class="inline-block h-full lg:pr-12">
        <login id="login" :selectedLang="getSelectedLang" :loginlinks="loginlinks"></login>
      </li>
      <li class="flex flex-col-reverse lg:flex-row lg:justify-between lg:items-stretch lg:pt-8">
        <searchicon v-if="$mq === 'xl'" @searchToggled="searchActive = true"></searchicon>
        <searchfield v-else :selectedLang="getSelectedLang" :homelink="homelink"></searchfield>

        <langswitcher :langs="langs" class="ml-24 lg:m-0"></langswitcher>
      </li>
    </ul>
    <desktopsearch
      v-if="$mq === 'xl' && searchActive"
      :class="{ deactivated: isDeactivated }"
      @outsideClicked="searchActive = false"
      :selectedLang="getSelectedLang"
      :homelink="homelink"
    ></desktopsearch>
  </nav>
</template>

<script>
export default {
  name: "MetaNav",
  props: ["langs", "homelink", "loginlinks"],
  data() {
    return {
      searchIcon: this.homelink + "/assets/fixies/Lupe.svg",
      searchActive: false,
      isDeactivated: false
    };
  },
  methods: {
    deactivate: function() {
      this.isDeactivated = !this.isDeactivated;
    }
  },
  computed: {
    getSelectedLang: function() {
      if (this.langs[2].active) {
        return 2;
      } else if (this.langs[1].active) {
        return 1;
      } else {
        return 0;
      }
      return;
    }
  }
};
</script>

<style>
#login {
  @media screen and (min-width: 1024px) {
    width: 30rem;
  }
}
</style>
