<template>
  <nav>
    <ul v-if="navIsActive || $mq === 'xl' " class="mt-12 lg:mt-0">
      <li
        v-for="node in nodes"
        :key="node.id"
        class="mb-12 lg:mb-0 lg:mr-6 lg:inline-block"
        :class="{ active: node.node.isActive }"
      >
        <a :href="node.node.url" class="flex text-mainblack lg:text-maingrey hover:text-mainblack">
          <h3 class="lg:text-small">{{ node.node.title }}</h3>
        </a>
      </li>
    </ul>
    <!-- <subnav v-if="$mq == 'xl'" :subnavItems="subnavItems" class="text-small"></subnav> -->
  </nav>
</template>

<script>
export default {
  name: "MainNavigation",
  props: ["nodes", "navIsActive", "homelink", "langs"],
  filters: {
    pretty: function(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    }
  },
  methods: {
    expandSubnav: function() {}
  }
};
</script>

<style></style>
