<template>
  <div id="desksearch" @click="$emit('deactivate')">
    <div class="overlay" @click="outsideClick"></div>
    <searchfield class="searchfield bg-white" :selectedLang="selectedLang" :homelink="homelink"></searchfield>
  </div>
</template>

<script>
export default {
  name: "Desktopsearch",
  props: ["selectedLang", "homelink"],
  methods: {
    outsideClick: function() {
      console.log("outside clicked");
      this.$emit("outsideClicked");
    }
  }
};
</script>

<style lang="scss">
#desksearch {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 0.5;
}

.searchfield {
  z-index: 1;
}
</style>
