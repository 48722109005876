<template>
  <button aria-label="Dropdown Arrow" @click="toggle()">
    <img :src="pfeil" alt="Dropdown Pfeil" class="h-16" />
  </button>
</template>

<script>
export default {
  name: "AkkordeonArrow",
  props: ["homelink"],
  data() {
    return {
      pfeil: this.homelink + "/assets/fixies/dropDown_pfeil.svg",
      isExpanded: false
    };
  },
  methods: {
    toggle: function() {
      this.$emit("toggleAkkordeon");
    }
  }
};
</script>

<style></style>
