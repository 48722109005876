<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <path
      d="M3.4 17.3L28.1 42c1 1 2.9 1 3.9 0l24.7-24.7"
      fill="none"
      :stroke="color"
      stroke-width="6.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: ["color"]
};
</script>

<style>
</style>